import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Routes } from 'const';
import Feedback from 'containers/Feedback';
import Header from 'containers/Header';
import MainMenu from 'containers/MainMenu';
import { useAppContext } from 'contexts/App';
import clsx from 'helpers/clsx';
import { useAuth } from 'hooks/useAuth';
import { useUser } from 'hooks/useUser';

import classes from './LayoutMain.module.scss';

const LayoutMain: React.FC = ({ children }) => {
	const { isOpenedMenu } = useAppContext();
	const history = useHistory();
	const { pathname } = useLocation();
	const { isAuth } = useAuth();
	const { fetchUser, user } = useUser();
	const hideFeedBackOnPages = [Routes.PARTNERS_FILE, Routes.QUESTIONNAIRE, Routes.CONTRACT_GENERATION].some((path) => path === pathname);

	useEffect(() => {
		if (!isAuth) {
			history.replace(Routes.LOGIN);
		} else {
			fetchUser();
		}
	}, [isAuth]);

	return (
		<>
			{!hideFeedBackOnPages && <Feedback />}
			<div className={classes.root}>
				{user && <MainMenu />}
				<div className={clsx(classes.body, { [classes.body_withMenu]: isOpenedMenu })}>
					{user && <Header />}
					<div className={classes.content}>{children}</div>
				</div>
			</div>
		</>
	);
};

export default LayoutMain;
