import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ApolloProvider } from '@apollo/client';
import { clientGraphql } from 'api';
import { GLOVO_ROLES, PARTNER_ROLES, Routes } from 'const';
const Maintenance = lazy(() => import('containers/Maintenance'));
const AnnouncementPopup = lazy(() => import('containers/modals/ReleaseAnnouncement'));
import ProtectedRoute from 'containers/ProtectedRoute';
import AppContextProvider from 'contexts/App';
import GetStartedContextProvider from 'contexts/GetStarted';
import { CountryCodeEnum } from 'interfaces';
import { RoleEnum } from 'interfaces/users';
const LoginLayout = lazy(() => import('layouts/login'));
const Accounts = lazy(() => import('pages/Accounts'));
const BulkUpdate = lazy(() => import('pages/BulkUpdate'));
const ContractForm = lazy(() => import('pages/ContractForm'));
const ContractGeneration = lazy(() => import('pages/ContractGeneration'));
const Customers = lazy(() => import('pages/Customers'));
const DocumentPreview = lazy(() => import('pages/DocumentPreview'));
const Documents = lazy(() => import('pages/Documents'));
const Faq = lazy(() => import('pages/Faq'));
const ForgotPassword = lazy(() => import('pages/ForgotPassword'));
const Index = lazy(() => import('pages/Index'));
const LaaSOrders = lazy(() => import('pages/LaaSOrders'));
const Login = lazy(() => import('pages/Login'));
const NotFound = lazy(() => import('pages/NotFound'));
const Orders = lazy(() => import('pages/Orders'));
const Users = lazy(() => import('pages/Users'));
const StoreAddresses = lazy(() => import('pages/StoreAddresses'));
const Report = lazy(() => import('pages/Report'));
const Questionaire = lazy(() => import('pages/Questionnaire'));
const PowerOutage = lazy(() => import('pages/PowerOutage'));
const PaymentSchedule = lazy(() => import('pages/PaymentSchedule'));
const PartnersFilePermissions = lazy(() => import('pages/PartnersFilePermissions'));
const PartnersFile = lazy(() => import('pages/PartnersFile'));
const DownloadReportLayout = lazy(() => import('layouts/download'));
const DownloadInvoice = lazy(() => import('pages/DownloadInvoice'));
const RouteWithLayout = lazy(() => import('components/RouteWithLayout'));

import Loader from 'components/Loader';

import 'helpers/rum';
import 'i18n';

import store from './store';

import 'react-toastify/dist/ReactToastify.css';
import 'static/styles/index.scss';

const App: React.FC = () => {
	const { t } = useTranslation('meta');
	return (
		<>
			<Helmet>
				<title>{t('title')}</title>
				<meta name="description" content={t('description')} />
			</Helmet>
			<Switch>
				<ProtectedRoute path="/" exact component={Index} />
				<ProtectedRoute path={Routes.ORDERS} exact component={Orders} roles={Object.values(RoleEnum).filter((role) => role !== RoleEnum.CUSTOMER)} />
				<ProtectedRoute path={Routes.ORDER_BY_CODE} exact component={Orders} />
				<ProtectedRoute
					exact
					agreedCountry={[CountryCodeEnum.UA, CountryCodeEnum.KZ]}
					roles={[...GLOVO_ROLES, RoleEnum.CREDENTIALS_ADMIN, RoleEnum.CUSTOMER]}
					path={Routes.LAAS_ORDERS}
					component={LaaSOrders}
				/>
				<ProtectedRoute
					exact
					roles={[RoleEnum.CREDENTIALS_ADMIN, ...PARTNER_ROLES, ...GLOVO_ROLES]}
					path={Routes.PAYMENTSCHEDULE}
					component={PaymentSchedule}
				/>
				<ProtectedRoute
					path={Routes.USERS}
					exact
					component={Users}
					roles={[RoleEnum.CREDENTIALS_ADMIN, RoleEnum.STORE_ADMIN, RoleEnum.STORE_MANAGER, ...GLOVO_ROLES]}
				/>
				<ProtectedRoute path={Routes.ORDER_REPORT} exact component={Report} roles={[RoleEnum.ADMIN, RoleEnum.FINANCE]} />
				<ProtectedRoute
					path={Routes.DOCUMENTS}
					agreedCountry={CountryCodeEnum.UA}
					exact
					component={Documents}
					roles={Object.values(RoleEnum).filter((role) => role !== RoleEnum.MENUTOOL && role !== RoleEnum.CUSTOMER)}
				/>
				<ProtectedRoute
					exact
					path={Routes.ACCOUNTS}
					agreedCountry={CountryCodeEnum.UA}
					component={Accounts}
					roles={Object.values(RoleEnum).filter((role) => role !== RoleEnum.MENUTOOL && role !== RoleEnum.CUSTOMER)}
				/>
				<ProtectedRoute path={Routes.BULKUPDATE} exact component={BulkUpdate} roles={[RoleEnum.ADMIN, RoleEnum.FINANCE]} />
				<ProtectedRoute path={Routes.LOGIN} exact component={Login} layout={LoginLayout} />
				<RouteWithLayout path={Routes.FORGOT_PASSWORD} exact component={ForgotPassword} layout={LoginLayout} />
				<ProtectedRoute path={Routes.STORE_ADDRESSES} exact component={StoreAddresses} roles={[RoleEnum.CREDENTIALS_ADMIN, ...GLOVO_ROLES]} />
				<ProtectedRoute path={Routes.POWER_OUTAGE} exact component={PowerOutage} roles={[RoleEnum.CREDENTIALS_ADMIN, ...PARTNER_ROLES]} />
				<ProtectedRoute
					exact
					path={Routes.PARTNERS_FILE}
					agreedCountry={CountryCodeEnum.UA}
					component={PartnersFile}
					roles={[RoleEnum.CREDENTIALS_ADMIN, ...GLOVO_ROLES]}
				/>
				<ProtectedRoute path={Routes.CONTRACT_GENERATION} exact component={ContractGeneration} roles={[RoleEnum.CREDENTIALS_ADMIN, ...GLOVO_ROLES]} />
				<ProtectedRoute exact path={Routes.QUESTIONNAIRE} component={Questionaire} roles={[RoleEnum.CREDENTIALS_ADMIN, ...GLOVO_ROLES]} />
				<ProtectedRoute exact path={Routes.CONTRACT_FORM} component={ContractForm} roles={[RoleEnum.CREDENTIALS_ADMIN, ...GLOVO_ROLES]} />
				<ProtectedRoute exact path={Routes.DOCUMENT_PREVIEW} component={DocumentPreview} roles={[RoleEnum.CREDENTIALS_ADMIN, ...GLOVO_ROLES]} />
				<ProtectedRoute
					exact
					path={Routes.PARTNERS_FILE_PERMISSIONS}
					agreedCountry={CountryCodeEnum.UA}
					component={PartnersFilePermissions}
					roles={[RoleEnum.CREDENTIALS_ADMIN]}
				/>
				<ProtectedRoute
					exact
					agreedCountry={[CountryCodeEnum.UA, CountryCodeEnum.KZ]}
					component={Customers}
					path={Routes.CUSTOMERS}
					roles={[...GLOVO_ROLES, RoleEnum.CREDENTIALS_ADMIN]}
				/>
				<ProtectedRoute
					exact
					path={Routes.DOWNLOAD_INVOICE}
					agreedCountry={[CountryCodeEnum.UA]}
					component={DownloadInvoice}
					layout={DownloadReportLayout}
				/>
				<ProtectedRoute exact path={Routes.FAQ} component={Faq} />
				<RouteWithLayout component={NotFound} />
			</Switch>
		</>
	);
};

ReactDOM.render(
	<ApolloProvider client={clientGraphql}>
		<ReduxProvider store={store}>
			<Suspense fallback={<Loader />}>
				<ToastContainer hideProgressBar autoClose={3000} limit={3} icon={false} />
				<BrowserRouter basename={process.env.PUBLIC_URL}>
					<AppContextProvider>
						<GetStartedContextProvider>
							<AnnouncementPopup />
							<Maintenance>
								<App />
							</Maintenance>
						</GetStartedContextProvider>
					</AppContextProvider>
				</BrowserRouter>
			</Suspense>
		</ReduxProvider>
	</ApolloProvider>,
	document.getElementById('root'),
);
