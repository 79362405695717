import { HIDE_AMENDMENT_AUTHOR_DOMAINS } from 'const';
import { IListItem } from 'containers/modals/CreateEditUser/types';
import i18n from 'i18n';
import { IStoreAddress } from 'interfaces/storeAddress';
import { ICustomerData, IUser, RoleEnum } from 'interfaces/users';
import { uniq } from 'underscore';

import { getCountry } from './jwt';

export const translateUserRole = (role: RoleEnum) => {
	switch (role) {
		case RoleEnum.ADMIN:
			return i18n.t('roles:ADMIN');
		case RoleEnum.STORE_ADMIN:
			return i18n.t('roles:STORE_ADMIN');
		case RoleEnum.STORE_MANAGER:
			return i18n.t('roles:STORE_MANAGER');
		case RoleEnum.ACCOUNTANT:
			return i18n.t('roles:ACCOUNTANT');
		case RoleEnum.MENUTOOL:
			return i18n.t('roles:MENUTOOL');
		case RoleEnum.FINANCE:
			return i18n.t('roles:FINANCE');
		case RoleEnum.ACCOUNT:
			return i18n.t('roles:ACCOUNT');
		case RoleEnum.CUSTOMER:
			return i18n.t('roles:CUSTOMER');
		default:
			return null;
	}
};

export const getRolesUser = (user?: IUser, separator = ', ', executeRole?: RoleEnum[]) => {
	let roles = getRoles(user);
	if (executeRole?.length) roles = roles?.filter((role) => !executeRole.includes(role));
	return uniq(roles?.map(translateUserRole) as RoleEnum[]).join(separator);
};

export const getAddressesUsers = (user: IUser, addresses: IStoreAddress[]): IStoreAddress[] => {
	const userStores = getAddressUser(user)
		.map((userAddress) => addresses.find((store) => userAddress === store.storeAddressId) as IStoreAddress)
		.filter((store) => store)
		.sort((a, b) => (a.storeName < b.storeName ? -1 : 1));
	return userStores;
};

export const getCustomerAddresses = (user: IUser, data: ICustomerData[]): ICustomerData[] => {
	const userStores = getCustomerIds(user)
		.map((userAddress) => data.find((store) => userAddress === store.customerId) as ICustomerData)
		.filter((store) => store)
		.sort((a, b) => (a.name < b.name ? -1 : 1));
	return userStores;
};

export const getRoles = (user?: IUser): RoleEnum[] | undefined => {
	const currentCountryCode = getCountry();
	const currentCountry = user?.countries?.find((country) => country.country === currentCountryCode);
	return currentCountry?.roles;
};

export const getAddressUser = (user: IUser): number[] => {
	const currentCountryCode = getCountry();
	const currentCountry = user.countries?.find((country) => country.country === currentCountryCode);
	if (currentCountry) return currentCountry.userCountryStoreIds;
	return [];
};

export const getCustomerIds = (user: IUser): number[] => {
	const currentCountryCode = getCountry();
	const currentCountry = user.countries?.find((country) => country.country === currentCountryCode);
	if (currentCountry) return currentCountry.userCountryCustomerIds;
	return [];
};

export const hasRole = (userRoles: RoleEnum[] | undefined, roles: RoleEnum[] | undefined): boolean => {
	return userRoles?.some((userRole) => roles?.some((role) => role === userRole)) || false;
};

export const getMaxRoleForEdit = (userRoles: RoleEnum[]): RoleEnum | undefined => {
	const { STORE_ADMIN, STORE_MANAGER, ACCOUNT } = RoleEnum;
	const haveEditRule = [ACCOUNT, STORE_ADMIN, STORE_MANAGER];
	const maxRole = haveEditRule.find((role) => userRoles.find((uRole) => role === uRole));
	return maxRole;
};

export const getUserStoresIds = (user?: IUser): number[] | undefined => {
	const currentCountryCode = getCountry();
	const currentCountry = user?.countries?.find((country) => country.country === currentCountryCode);
	return currentCountry?.userCountryStoreIds;
};

export const parseStoreIds = (stores: IListItem[]) => {
	return stores.map(({ storeAddressId }) => storeAddressId);
};

export const getAvailableRoles = (comparisonRole: RoleEnum | undefined): RoleEnum[] | undefined => {
	if (!comparisonRole) return;
	const staticRoles = Object.values(RoleEnum);
	const comparisonIndex = staticRoles.indexOf(comparisonRole);
	return staticRoles.filter((role) => comparisonIndex <= staticRoles.indexOf(role));
};

export const renderAuthor = (roles: RoleEnum[] = [], email: string | null, defaultAuthor = 'Glovo') => {
	if (hasRole([RoleEnum.ADMIN, RoleEnum.FINANCE], roles)) return email;
	return defaultAuthor;
};

export const renderAuthorEmail = (roles: RoleEnum[] = [], email: string | null, defaultAuthor = 'Glovo') => {
	if (hasRole([RoleEnum.ADMIN, RoleEnum.FINANCE, RoleEnum.ACCOUNT], roles)) return email;
	if (HIDE_AMENDMENT_AUTHOR_DOMAINS.some((domain) => email?.includes(domain))) return defaultAuthor;
	return email;
};
