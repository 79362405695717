import React, { lazy, useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { APP_PREFIX } from 'const';
const OrderModal = lazy(() => import('containers/Orders/Modal'));
import { addYears } from 'date-fns';
import clsx from 'helpers/clsx';
import { useAuth } from 'hooks/useAuth';
import { useLaaSOrders } from 'hooks/useLaaSOrders';
import { useOrders } from 'hooks/useOrders';

import { FAKE_ORDER } from './consts';
import { IGetStartedContext, IStep } from './types';

import classes from './GetStarted.module.scss';

export const GetStartedContext = React.createContext<IGetStartedContext | null>(null);
export const useGetStartedContext = () => {
	const currentGetStartedContext = useContext(GetStartedContext);

	if (!currentGetStartedContext) {
		throw new Error('useGetStartedContext has to be used within <GetStartedContext.Provider>');
	}

	return currentGetStartedContext;
};

const sortSteps = (a: IStep, b: IStep) => a.sort - b.sort;
const STORAGE_NAME = `${APP_PREFIX}getStarted`;

const Provider: React.FC = ({ children }) => {
	const [steps, setSteps] = useState<IStep[]>([]);
	const [currentStepNumber, setCurrentStepNumber] = useState<number>(-1);
	const [cookies, setCookie] = useCookies();
	const [open, setOpen] = useState<boolean>(false);
	const [isReady, setIsReady] = useState<boolean>(false);
	const [openModalOrder, setOpenModalOrder] = useState<boolean>(false);
	const currentStep = steps[currentStepNumber];
	const isLastStep = currentStepNumber + 1 >= steps.length;
	const { isAuth } = useAuth();
	const { onload: onloadOrders } = useOrders();
	const { onload: onloadLaaSOrders } = useLaaSOrders();

	useEffect(() => {
		if (cookies[STORAGE_NAME]) {
			setIsReady(true);
		} else if (!onloadOrders || !onloadLaaSOrders) {
			setOpen(true);
			setIsReady(true);
		}
	}, [onloadOrders, onloadLaaSOrders]);

	const onClose = () => {
		setOpen(false);
		setCurrentStepNumber(-1);
		setOpenModalOrder(false);
		setCookie(STORAGE_NAME, true, {
			path: '/',
			expires: addYears(new Date(), 1),
		});
	};

	const onOpen = () => {
		setOpen(true);
	};

	const pushStep = (step: IStep) => {
		setSteps((state) => {
			if (state.find((it) => it.id === step.id)) {
				return [...state];
			}
			return [...state, step].sort(sortSteps);
		});
	};

	const deleteStep = (step: IStep) => {
		setSteps((state) => [...state].filter((it) => it.id !== step.id));
	};

	const nextStep = () => {
		currentStep?.beforeNext?.(currentStep);
		if (!isLastStep) {
			setCurrentStepNumber((state) => state + 1);
		} else {
			onClose();
		}
		currentStep?.afterNext?.();
	};

	const prevStep = () => {
		if (currentStep.beforePrev) {
			currentStep?.beforePrev?.();
		} else {
			const prevPrevStep = steps[currentStepNumber - 2];
			prevPrevStep?.beforeNext?.();
		}
		setCurrentStepNumber((state) => state - 1);
	};

	const onCloseModalOrder = () => {
		setOpenModalOrder(false);
	};

	const onOpenModalOrder = () => {
		setOpenModalOrder(true);
	};

	const value: IGetStartedContext = {
		steps,
		count: steps.length,
		currentStep,
		currentStepNumber,
		isLastStep,
		open,
		isReady,
		onClose,
		onOpen,
		pushStep,
		nextStep,
		prevStep,
		deleteStep,
		onOpenModalOrder,
		onCloseModalOrder,
	};

	const handleClose = () => null;
	return (
		<GetStartedContext.Provider value={value}>
			{children}
			{isAuth && (
				<>
					<OrderModal
						open
						onClose={handleClose}
						item={FAKE_ORDER}
						rootClassName={clsx(classes.root, {
							[classes.hide]: !openModalOrder,
						})}
						withProviderGetStarted
						isFake
					/>
				</>
			)}
		</GetStartedContext.Provider>
	);
};

export default Provider;
