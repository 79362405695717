import { ApolloClient, createHttpLink, InMemoryCache, split } from '@apollo/client';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { ClientNameEnum } from 'const';
import sha256 from 'crypto-js/sha256';
import { getCountry } from 'helpers/jwt';
import { getTokenSync, hasToken } from 'helpers/token';
import { IFetchOptions } from 'interfaces';

import { getInMemoryCacheTypePolicies } from './cacheTypePolicies';
import fetchWithToken from './fetchWithToken';
import typeDefs from './schema';

export const prefixApiUrl = process.env.REACT_APP_PREFIX_API_URL || '';
export const prefixApiAuthUrl = process.env.REACT_APP_PREFIX_API_AUTH_URL || '';
export const prefixApiFeedbackUrl = process.env.REACT_APP_PREFIX_API_FEEDBACK_URL || '';
export const prefixApiFintoolContracts = process.env.REACT_APP_PREFIX_FINTOOL_CONTRACTS_API_URL || '';

export const httpLinkAuth = createHttpLink({
	uri: `${prefixApiAuthUrl}/api-auth/graphql`,
	fetch: fetchWithToken as WindowOrWorkerGlobalScope['fetch'],
});

export const httpLinkCommon = createHttpLink({
	uri: () => `${prefixApiUrl}/${getCountry()?.toLocaleLowerCase()}/api/graphql`,
	fetch: fetchWithToken as WindowOrWorkerGlobalScope['fetch'],
});

export const httpLink = split((operation) => operation.getContext().clientName === ClientNameEnum.AUTH, httpLinkAuth, httpLinkCommon);

export const getWsUri = () => {
	const countryPrefix = `/${getCountry?.()?.toLocaleLowerCase() || ''}`;
	if (process.env.NODE_ENV === 'production') {
		return `wss://${window.location.host}${countryPrefix}/subscriptions`;
	}
	const protocol = prefixApiUrl.includes('https') ? 'wss' : 'ws';
	return `${protocol}://${prefixApiUrl.replace(/https?:\/\//, '')}${countryPrefix}/subscriptions`;
};

export const wsLink = new WebSocketLink({
	uri: getWsUri(),
	options: {
		reconnect: true,
		// reconnectionAttempts: 5,
		lazy: true,
		inactivityTimeout: 1000,
		connectionParams: () => ({
			skipSSLValidation: true,
			...(hasToken() && { Authorization: `Bearer ${getTokenSync()}` }),
		}),
	},
});

export const splitLink = split(
	({ query }) => {
		const definition = getMainDefinition(query);
		return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
	},
	wsLink,
	createPersistedQueryLink({ sha256: (q) => sha256(q).toString() }).concat(httpLink),
);

export const clientGraphqlCache = new InMemoryCache({
	typePolicies: getInMemoryCacheTypePolicies(),
});

export const clientGraphql = new ApolloClient({
	link: splitLink,
	cache: clientGraphqlCache,
	typeDefs,
});

export interface IFetchResponse<T> {
	body: T;
	response: Response;
}

const getBody = (response: Response) => {
	const contentType = response.headers.get('content-type');
	if (contentType?.includes('json')) {
		return response.json();
	}
	if (
		contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
		contentType === 'application/zip' ||
		contentType === 'application/pdf' ||
		contentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
	) {
		return response.blob();
	}
	return response.text();
};

const getPrefixApiUrl = (options?: IFetchOptions): string => {
	if (options?.feedback) return prefixApiFeedbackUrl;
	if (options?.fintoolContracts) return `${prefixApiFintoolContracts}/contracts`;
	if (process.env.NODE_ENV === 'production') return '';
	if (options?.auth) return prefixApiAuthUrl;
	return prefixApiUrl;
};

const getCountryPrefix = (options: IFetchOptions) => {
	return !options.auth ? `/${getCountry()?.toLocaleLowerCase()}` : '';
};

export const fetch = <T>(url: string, options: IFetchOptions = {}, withCountryCode = true): Promise<IFetchResponse<T>> => {
	const prefix = getPrefixApiUrl(options);
	return new Promise((resolve, reject) => {
		fetchWithToken(`${prefix}${withCountryCode ? getCountryPrefix(options) : ''}${url}`, options)
			.then(async (response) => {
				if (response.status >= 200 && response.status < 300) {
					resolve({
						body: await getBody(response),
						response,
					});
				} else {
					reject({
						body: response.clone().json(),
						response,
					});
				}
			})
			.catch(reject);
	});
};
