import { datadogRum } from '@datadog/browser-rum';

const isProd = process.env.NODE_ENV === 'production';

if (isProd) {
	datadogRum.init({
		applicationId: process.env.REACT_APP_RUM_APPLICATION_ID || '',
		clientToken: process.env.REACT_APP_RUM_CLIENT_TOKEN || '',
		site: process.env.REACT_APP_RUM_SITE || '',
		service: process.env.REACT_APP_RUM_SERVICE || '',
		env: process.env.REACT_APP_ENV,
		traceSampleRate: 100,
		trackUserInteractions: true,
		defaultPrivacyLevel: 'mask-user-input',
		sessionReplaySampleRate: 0,
	});
}

export const setDatadogRumUser = (user: { id: string }) => {
	isProd && datadogRum.setUser(user);
};
